<template>
  <div class="item">
    <Breadcrumb :sn="sn"></Breadcrumb>
    <el-row class="content">
      <el-col :span="24">
        <div class="titBox">
          <TitleHeader msg="申请售后" :noBorder="true"></TitleHeader>
        </div>
      </el-col>
      <el-row class="wraper">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-col>
            <OrderTiltle :back="true"></OrderTiltle>
            <el-col class="goodsInfo">
              <OrderInfoStem
                v-for="(item, index) in orderdetail.orderDetails"
                :key="index"
                :indexNum="index"
                :goodsInfo="item"
                @GoodsItemFun="dataFun"
              ></OrderInfoStem>
            </el-col>
          </el-col>
          <div class="conBox">
            <el-form-item label="售后类型" prop="resource">
              <ul class="nav">
                <li
                  v-for="(item, index) in obj.content"
                  :key="index"
                  :type="item.type"
                  :class="obj.currentIndex === item.type ? 'active' : ''"
                  @click="changeIndex(item.type)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </el-form-item>
            <el-col v-if="obj.currentIndex == 2">
              <el-form-item label="退货方式" prop="backStyle">
                <el-select
                  v-model="backStyleSelected"
                  placeholder="请选择"
                  @change="backStyleFun"
                >
                  <el-option
                    v-for="item in backStyle"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col class="desc">
              <el-form-item label="问题描述" prop="desc">
                <el-input
                  type="textarea"
                  v-model="form.desc"
                  maxlength="50"
                  show-word-limit
                  placeholder="请填写问题描述"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-form-item label="图片上传">
              <el-upload
                action="#"
                list-type="picture-card"
                multiple
                :limit="3"
                :show-file-list="boolean"
                :on-exceed="handleExceed"
                :before-upload="beforeUpload"
                :auto-upload="false"
                :on-change="imgSaveToUrl"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :file-list="fileImgList"
                ref="upload"
              >
                <em class="el-icon-plus"></em>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
          </div>

          <el-form-item>
            <el-button type="danger" @click.once="submitForm('form')"
              >确定</el-button
            >
          </el-form-item>
        </el-form>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import {
  OrderDetail,
  referOrderDetail,  //子订单提交退单
  referOrder,   //总订单退
  putFile,
} from "api/order.js";
const TitleHeader = () => import("components/common/TitleHeader.vue");
const Breadcrumb = () => import("components/common/Breadcrumb.vue");
const OrderInfoStem = () => import("components/order/OrderInfoStem.vue");
const OrderTiltle = () => import("components/order/OrderTiltle.vue");

export default {
  name: "ApplySales",
  data() {
    return {
      sn: "",
      //导航栏信息
      obj: {
        content: [
          {
            type: 2,
            name: "退货退款",
          },
          {
            type: 1,
            name: "退款服务",
          },
        ],
        currentIndex: 1,
      },
      //商品列表数据
      orderdetail: "",
      // 退货方式
      backStyleSelected: "",
      backStyle: [
        {
          label: "拒收",
          value: 1,
        },
        {
          label: "寄回",
          value: 2,
        },
        {
          label: "未收货",
          value: 3,
        },
      ],
      info: "",
      //退货商品
      salesArr: [],
      form: {
        resource: 1, //退货类型 默认退款   // 退款方式
        desc: "", //问题描述
      },
      // rules: {
      //   desc: [{ required: true, message: "必填", trigger: "blur" }],
      // },
      //图片上传
      //是否展示图片列表
      boolean: true,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      posterURL: "",
      // //退货详情
      refund4DedtailDTOS: [],
      refund: "",
      // 上传图片路径集合
      productPics: [],
      //上传图片合集
      fileImgList: [],
    };
  },
  methods: {
    // 选择退货方式
    backStyleFun(params) {
      this.backStyleSelected = params; //退货方式
    },

    //子组件回传 修改商品数量方法
    dataFun(data) {
      // {refundCount: 退货商品数量, indexNum:第几条商品   id：商品id}
      this.refund4DedtailDTOS.forEach((item, i) => {
          if (item.id === data.id) {
             this.refund4DedtailDTOS.splice(i,1,data);
          }
        });
    },
    submitForm(formName) {
      // if (this.fileImgList.length === 0) {
      //   this.$message.error("请选择图片文件");
      //   return false;
      // }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //类型
          // 1普通订单（a.如果里面有不可售后商品，则子订单退 b.如果里面没有不可售后商品【b1.商品数量全部退，则走总订单退，b2.数量不是全部退，则走子订单退】）
          // 2活动订单（总订单退）
          if (this.orderdetail.orderType === 1) {   //普通订单
            let isCanFlag=true;  //默认商品为都可售后
             this.refund4DedtailDTOS.forEach((item) => {
                  if(item.isCan===0){
                    isCanFlag=false    //存在不可售后商品
                  }
            });
            if(isCanFlag){
              // 没有不可售后商品
              let AllbackNumFlag=true;
              this.refund4DedtailDTOS.forEach((item) => {
                if (item.refundCount !== item.counts) {
                    AllbackNumFlag=false
                }
              });
              if(AllbackNumFlag){
                  // 商品全部退  走总订单接口
                   this.SpecialRefund()
              }else{
                  // 数量部分退  走子订单接口
                  this.CommonRefund()
              }
            }else{
              // 存在不可售后商品  走子订单退款
              this.CommonRefund()
            }
          }
          else{
            // 活动订单  走整单退
             this.SpecialRefund()
          }
        } else {
          return false;
        }
      });
    },
    CommonRefund(){
      // 走子订单接口
                let refund4DedtailDTOSArr=[];
                this.refund4DedtailDTOS.forEach((item) => {
                   if(Number(item.refundCount) !== 0){
                     if(item.isCan!==0){
                        refund4DedtailDTOSArr.push({
                          id: item.id,
                          refundCount: item.refundCount,
                        })
                     }
                     
                   }
                });
                this.refund = {
                  note: "1",
                  orderDetailId: "", //订单详情编号集合
                  orderId: sessionStorage.getItem("orderId"), //订单编号
                  productPics: "", //图片集合
                  question: this.form.desc, //问题
                  refund4DedtailDTOS: [], //退货详情
                  refundMethod: this.form.resource, //退款方式（1只退款，2退货退款）
                };
                if (this.form.resource == 2) {
                  this.refund.drawMethod = this.backStyleSelected; //1拒收2寄回3--未收货
                } else {
                  this.$delete(this.refund, "drawMethod"); //vue方法
                }
                let productPics = "";
                // if(this.productPics.length===0){
                //    this.$message('图片上传中');
                //   return false
                // }
                this.productPics.forEach((item) => {
                  productPics += item.link + ",";
                });
                this.refund.productPics = productPics;
                this.refund.refund4DedtailDTOS =refund4DedtailDTOSArr;
                let orderDetailId = "";
                refund4DedtailDTOSArr.forEach((item) => {
                  orderDetailId += item.id + ",";
                });
                this.refund.orderDetailId = orderDetailId;
                //普通订单（子订单提交退单 /blade-order/refund/referOrderDetail)
                referOrderDetail(this.refund).then((data) => {
                  if (data.data.code === 400) {
                    this.$message(data.data.msg);
                  } else {
                    this.$message({
                      message: data.data.msg,
                      type: "success",
                    });
                    // // 提交成功，跳转售后列表页面
                    this.$router.push({ name: "AfterRefund", query: { ids: sessionStorage.getItem("orderId") }  });
                  }
                });
     },
     SpecialRefund(){
      //  走整订单退
            let refund4DedtailDTOSArr=[];
                this.refund4DedtailDTOS.forEach((item) => {
                      refund4DedtailDTOSArr.push({
                        id: item.id,
                        refundCount: item.refundCount,
                      })
                });
           this.refund = {
              note: "1",
              orderDetailId: "", //订单详情编号集合
              orderId: sessionStorage.getItem("orderId"), //订单编号
              productPics: "", //图片集合
              question: this.form.desc, //问题
              refundMethod: this.form.resource, //退款方式（1只退款，2退货退款）
            };
            if (this.form.resource == 2) {
              this.refund.drawMethod = this.backStyleSelected; //1拒收2寄回3--未收货
            } else {
              this.$delete(this.refund, "drawMethod"); //vue方法
            }
                // if(this.productPics.length===0){
                //   this.$message('图片上传中');
                //   return false
                // }
            let productPics = "";
            this.productPics.forEach((item) => {
              productPics += item.link + ",";
            });
            this.refund.productPics = productPics;
            let orderDetailId = "";
            refund4DedtailDTOSArr.forEach((item) => {
              orderDetailId += item.id + ",";
            });
            this.refund.orderDetailId = orderDetailId;
            //活动订单（总订单提交退单 /blade-order/refund/referOrder）
            referOrder(this.refund).then((data) => {
              if (data.data.code === 400) {
                this.$message(data.data.msg);
              } else {
                this.$message({
                  message: data.data.msg,
                  type: "success",
                });
                // // 提交成功，跳转售后列表页面
                this.$router.push({ name: "AfterRefund", query: { ids: sessionStorage.getItem("orderId") } });
              }
            });
     },
    // 选取图片后自动回调，里面可以获取到文件
    imgSaveToUrl(file) {
      let SAMEJPG = false;
      this.fileImgList.forEach((item) => {
        if (
          item.size == file.size &&
          item.lastModified == file.lastModified &&
          item.name == file.name
        ) {
          SAMEJPG = true;
          return false;
        }
      });
      this.fileImgList.push(file);

      let fileName = file.name;
      let regex = /(.jpg|.jpeg|.gif|.png|.bmp)$/;
      if (regex.test(fileName.toLowerCase())) {
        this.posterURL = file.url;
      } else {
        this.fileImgList = this.fileImgList.splice(
          0,
          this.fileImgList.length - 1
        );
        this.$message.error("请选择图片文件");
      }
      if (SAMEJPG) {
        this.fileImgList = this.fileImgList.splice(
          0,
          this.fileImgList.length - 1
        );
        this.$message.error("不能上传相同图片文件");
      } else {
        let formfile = new FormData();
        formfile.append("file", file.raw);
        putFile(formfile).then((data) => {
          this.productPics.push(data.data.data);
        });
      }
    },
    //移除
    handleRemove(file) {
      // , fileList
      this.productPics.forEach((item, i) => {
        if (item.originalName == file.name) {
          this.productPics.splice(i, 1);
        }
      });
    },
    //预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //定义超出限制时
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    changeIndex(index) {
      this.obj.currentIndex = index;
      this.form.resource = index;
    },
    // 获取订单商品数据
    getOrderDetail() {
        // 显示loading
    this.$loading({ fullscreen: true ,background:'#ffffff45'})
      OrderDetail(sessionStorage.getItem("orderId")).then((res) => {
        this.orderdetail = res.data.data;
         // 关闭loading
          this.$loading().close();
        this.orderdetail.orderDetails.forEach((item) => {
                  item.orderType=this.orderdetail.orderType
        })
       
        this.orderdetail.orderDetails.forEach((item) => {
          let maxCount=0;
          if(item.refundCount>-1){
              maxCount=Number(item.goodsCount)-Number(item.refundCount)
          }else{
              maxCount=Number(item.goodsCount)
          }
          this.refund4DedtailDTOS.push({
            id: item.id,
            refundCount: maxCount,
             counts:maxCount,
             isCan:item.isCan
          });
        });
      });
    },
  },
  created() {
   
    this.getOrderDetail();
    this.sn = sessionStorage.getItem("orderId");
  },
  components: {
    TitleHeader,
    Breadcrumb,
    OrderInfoStem,
    OrderTiltle,
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
.titBox {
  padding: 20px;
  border: 1px solid #f0f0f0;
  border-bottom: none;
}
.item {
  .nav {
    li {
      padding: 3px 3px;
      width: 86px;
      height: 28px;
      display: inline-block;
      border: 2px solid #f0f0f0;
      margin-right: 30px;
      text-align: center;
      line-height: 28px;
      img {
        width: 100%;
        height: 100%;
      }
      &.active {
        border: 2px solid #FFD303;
        background: url("../../assets/checkedRed.png") no-repeat right bottom;
        background-size: 26px 26px;
      }
    }
  }
  .content {
    min-height: 500px;
    background: #fff;
    margin-bottom: 20px;

    .wraper {
      float: left;
      padding: 0 34px;
      .el-input {
        width: 300px;
      }
    }
  }
  .conBox {
    padding: 30px 80px;
    border: 1px solid #999999;
    float: left;
    width: calc(100% - 162px);
    margin-top: 40px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  .el-form-item {
    padding-top: 20px;
    float: left;
  }
  .desc {
  }
  .el-textarea {
    display: inline-block;
    width: 337px;
    vertical-align: bottom;
  }
  /deep/.el-upload--picture-card {
    background-color: #fff;
    border: 1px dashed #ccc;
    border-radius: 0px;
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    line-height: 80px;
    vertical-align: top;
    color: #d8d8d8;
  }
  .el-button {
    width: 136px;
    background: #FFD303;
    color: #000;
  }
  .el-button span {
    display: inline-block;
    height: 36px;
    line-height: 36px;
    .fonts();
  }
}
</style>
